input[type="radio"] {
    margin-right: 0.75rem !important;
    min-height: 1.25rem !important;
    min-width: 1.25rem !important;
    accent-color: $primary !important;
    mix-blend-mode: darken;
    outline: auto;
    margin-left: 0.125rem;

    &:checked {
        outline-color: $primary;
    }
}

input[type='radio']:before {
    content: '';
    display: block;
    width: 60%;
    height: 60%;
    margin: 10%; /* Keeping margin only 10% */
    padding: 10%; /* Increase the inner area for pushing the border out of the circle */
    border-radius: 50% !important;
    /*transition: 0.3s;*/ /* Move your transition to here */
}
