﻿h1 {
    @include text-size(2rem, 2.5, 600);
    text-transform: capitalize;
    &.l {
        font-size: 2.5rem;
        line-height: 3.25rem;
        text-transform: none;
    }

    &.m {
        font-weight: 700;
    }
}

h2 {
    @include text-size(1.625rem, 2, 600);
    letter-spacing: 0.01em;
    text-transform: capitalize;
    &.l {
        text-transform: none;
    }
}

h3 {
    @include text-size(1.625rem, 1.23, 600);
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.01em;
    &.m {
        font-weight: 700;
    }
}

/* the following are deprecated and should no longer be used */
h4 {
    font-weight: 400;
    font-size: 1.375rem;
    line-height: 1.75;
    text-transform: capitalize;
}

h5 {
    font-weight: 500;
    font-size: 1rem;
    line-height: 2;
    letter-spacing: 0.013em;
}

h6 {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.75;
    letter-spacing: 0.007em;

    &.l {
        font-weight: 600;
    }
}
