﻿.card {
  height: fit-content;
  &.bg-primary-light {
    background: $primary-0;
  }
  &.bg-secondary-light {
    background: $tertiary-0;
  }
  &.bg-neutral-light {
    background: $neutral-20;
  }
}

.card-actions {
  & button {
    width: fit-content;
  }
}
