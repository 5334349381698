﻿$border-radius: 100%;

@mixin icon-style-size($icon-square-size, $font-size) {
  width: $icon-square-size;
  height: $icon-square-size;
  border-radius: $border-radius;
  & span {
    font-size: $font-size;
  }
}

.ls-icon-x-small {
  @include icon-style-size(1.25rem, 0.625rem);
}
.ls-icon-small {
  @include icon-style-size(2rem, 1rem);
}
.ls-icon-medium {
  @include icon-style-size(2.75rem, 1.5rem);
}
.ls-icon-large {
  @include icon-style-size(3.5rem, 1.875rem);
}
.ls-icon-x-large {
  @include icon-style-size(4.5rem, 2.5rem);
}
.ls-icon-primary {
  @include background-and-color($primary, $on-primary);
}
.ls-icon-primary-light {
  @include background-and-color($primary-container, $on-primary-container);
}
.ls-icon-primary-tooltip {
  @include background-and-color(transparent, $primary);
  &:hover {
    color: $primary-hover !important;
  }
}
.ls-icon-primary-no-background {
  @include background-and-color(transparent, $primary);
}
.ls-icon-secondary {
  @include background-and-color($secondary, $on-secondary);
}
.ls-icon-secondary-light {
  @include background-and-color($secondary-container, $on-secondary-container);
}

.ls-icon-tertiary-no-background {
  @include background-and-color(transparent, $on-tertiary-container);
}
.ls-icon-tertiary-light {
  @include background-and-color($tertiary-container, $on-tertiary-container);
}

.ls-icon-error-no-background {
  @include background-and-color(transparent, $error);
}
.ls-incomplete-warning-icon {
  color: $on-tertiary-container;
  width: 1.375rem;
  height: 1.188rem;
  padding: 0.25rem;
}
