.embeddedServiceHelpButton {
	font-family: "Montserrat" !important;
}

.helpButton {
	bottom: 0.625rem !important;
}

.embeddedServiceHelpButton .helpButton .uiButton, .minimizedContainer {
	justify-content: center !important;
	align-items: center !important;
	margin-bottom: 3.5rem;
	padding: 0.938rem 1.375rem 0.938rem 1.188rem;
	box-shadow: 0 0 0 0 !important;
	color: $on-secondary !important;
	background-color: $secondary !important;
	min-width: 16.188rem !important;
	height: 3.5rem !important;
	border-radius: 1rem 1rem 0 0 !important;

	&:hover::before {
		border-radius: 1rem 1rem 0 0 !important;
	}

	&:focus::before {
		border-radius: 1rem 1rem 0 0 !important;
	}
}

.dockableContainer {
	border-radius: 1rem 1rem 0 0 !important;
	box-shadow: 0 0.25rem 0.25rem 0 rgba(38, 38, 38, 0.3), 0 0.5rem 0.75rem 0.375rem rgba(38, 38, 38, 0.15) !important;
}

.startButton, .dialog-button-0 {
	background: $primary !important;
	color: $on-primary !important;
}

.waitingCancelChat, .dialog-button-1 {
	color: $on-surface-variant !important;
	background: none !important;
	border-color: $on-surface !important;
	border: solid 0.063rem !important;
}

.endChatButton {
	background: $secondary !important;
}

.chatHeaderBranding {
	background-color: $on-surface !important;
	padding: 0.625rem 0.625rem 0.625rem 1rem !important;
}

.embeddedServiceIcon{
	margin-right: 0;
}

.helpButtonLabel {
	-webkit-flex-grow: 0 !important;
	flex-grow: 0 !important;
}

.embeddedServiceLiveAgentStateChatPlaintextMessageDefaultUI {
	border-radius: 0.75rem !important;
	padding: 0.5rem 0.75rem !important;
	&.agent {
		background: $surface-variant !important;
		color: $on-surface !important;
	}
	&.chasitor {
		background: $on-primary-container !important;
		color: $on-primary !important;
	}
}