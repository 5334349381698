@import '../../styles/theme-colors.scss';

@mixin btn($name, $bg-color, $color, $hover-bg, $focus-border, $pressed-bg, $disabled-bg) {
    &.btn-#{$name} {
        background: $bg-color !important;
        color: $color;

        &:hover {
            color: $color;
            background: $hover-bg !important;
            box-shadow: $box-shadow-hovered !important;
        }

        &:focus {
            color: $color;
            border: solid 0.125rem $focus-border;
        }

        &:active {
            color: $color !important;
            background: $pressed-bg !important;
        }

        &:disabled {
            color: $on-surface-38 !important;
            background: $disabled-bg !important;
        }
    }
}

@mixin btn-borderless($name, $color, $hover-color, $focus-color, $pressed-color) {
    &.btn-#{$name}-borderless {
        border: none;
        background: transparent !important;
        color: $color !important;

        &:hover {
            box-shadow: none;
            color: $hover-color !important;
        }

        &:focus {
            box-shadow: none;
            color: $focus-color !important;
        }

        &:active {
            color: $pressed-color !important;
        }

        &:disabled {
            color: $on-surface-38 !important;
        }
    }
}

button {
    font-weight: 700 !important;
    font-size: 1rem !important;
    line-height: 1.5rem !important;
    letter-spacing: 0.04em !important;
    text-transform: none !important;
    border-radius: 0.75rem !important;

    &.btn {
        box-shadow: $box-shadow-0 !important;
        width: fit-content;
        height: 3.5rem;

        &:disabled {
            box-shadow: $box-shadow-0 !important;
            border: 0px;
        }

        &.btn-sm {
            height: 3rem;
            padding: 0.75rem 1.25rem;
        }
    }

    &.btn-icon {
        display: flex;
        align-items: center;
    }

    @include btn(primary, $primary, $on-primary, $primary-hover, $primary-hover, $primary-pressed, $primary-disabled);
    @include btn(secondary, $secondary, $on-secondary, $secondary-hover, $secondary-hover, $secondary-pressed, $secondary-disabled);
    @include btn-borderless(primary, $primary, $primary-hover, $primary, $primary-pressed);
    @include btn-borderless(text, $on-surface-variant, $background, $on-surface, $surface);

    &.btn-do-it-later {
        line-height: 1.625rem !important;
    }

    &.btn-outline-primary {
        color: $primary !important;
        border-color: $primary !important;
        border-width: 0.063rem  !important;

        &:hover {
            background: rgba($background, 0) !important;
            box-shadow: $box-shadow-hovered !important;
            border: solid 0.063rem $primary-hover !important;
        }

        &:focus {
            border: solid 0.125rem $primary !important;
        }

        &:active {
            border-color: $primary-pressed !important;
        }
    }

    &.btn-outline-tertiary {
        color: $on-surface-variant !important;
        border-color: $on-surface !important;
        border-width: 0.063rem  !important;

        &:hover {
            color: $on-surface-variant !important;
            background: rgba($background, 0) !important;
            box-shadow: $box-shadow-hovered !important;
            border: solid 0.063rem $outline !important;
        }

        &:focus {
            color: $on-surface-variant !important;
            background: rgba($background, 0) !important;
            border: solid 0.125rem $on-surface !important;
        }

        &:active {
            color: $on-surface-variant !important;
            background: rgba($background, 0) !important;
            border-color: $inverse-surface !important;
        }

        &:disabled,
        &.disabled,
        fieldset:disabled & {
            border: solid 0.0625rem $outline-38 !important;
            color: $on-surface-38 !important;
        }

        &.btn-floating {
            &.btn-lg span {
            }

            &:hover {
                border-color: $primary-40 !important;
                box-shadow: $box-shadow-hovered !important;
            }

            &:focus {
                box-shadow: $box-shadow-tertiary-floating-focused !important;
                border-color: $primary-40 !important;
                border-width: 0.125rem !important;
            }

            &:active {
                border-color: $primary-50 !important;
            }
        }
    }

    &.btn-neutral {
        color: $neutral-variant-90 !important;
        background: $neutral-0 !important;

        &.btn-floating {
            &:hover {
                box-shadow: $box-shadow-hovered !important;
            }

            &:focus {
                box-shadow: $box-shadow-neutral-floating-focused !important;
                border-color: $neutral-80 !important;
                border-width: 0.125rem !important;
                border-style: solid !important;
            }

            &:active {
            }

            &.btn-md {
                &:active {
                    background: $neutral-10 !important;
                }
            }
        }
    }

    &.btn-floating {
        min-width: 3.5rem !important;
        min-height: 3.5rem !important;
        width: 3.5rem !important;
        height: 3.5rem !important;

        &.btn-md {
            min-width: 2.5rem !important;
            min-height: 2.5rem !important;
            width: 2.5rem !important;
            height: 2.5rem !important;
        }

        &.btn-md span {
            font-size: 1.5rem;
            position: relative;
            top: 0.16rem;
            left: 0.02rem;
        }

        &.btn-lg span {
            font-size: 2rem;
            position: relative;
            top: 0.16rem;
        }
    }

    > span {
        &.left-icon {
            margin-right: 0.75rem;
        }
        &.right-icon {
            margin-left: 0.75rem;
        }
    }
}

.lbl-btn-lg {
    font-weight: 700 !important;
    font-size: 1rem !important;
    line-height: 1.5rem !important;
    letter-spacing: 0.04em !important;
    text-transform: none !important;
    border-radius: 0.75rem !important;
    min-width: 7.375rem;
    height: 3.5rem;
    padding-top: 1rem;
}
