@import  '../../styles/theme-colors';

.table {
    thead {
        color: $neutral-10;
        border-color: $outline-38;
        background-color: $surface;
        height: 3.5rem;
        th.header-cell {
            vertical-align: middle;
        }
    }
}

.additional-info {
    color: $inverse-surface;
    height: 1rem;
    line-height: 1.33;
    font-size: 0.75rem;
}