﻿// Company colors //
$primary-0: #132b16 !default;
$primary-10: #1e3f22 !default;
$primary-20: #2e5a34 !default;
$primary-30: #407547 !default;
$primary-40: #548e5c !default;
$primary-50: #6ca674 !default;
$primary-60: #86bb8d !default;
$primary-70: #a1cea7 !default;
$primary-80: #bddfc2 !default;
$primary-90: #d9ecdc !default;
$primary-95: #eff6f0 !default;
$primary-99: #f5f9f6 !default;

$secondary-0: #13020e !default;
$secondary-10: #2a0a1c !default;
$secondary-20: #381328 !default;
$secondary-30: #441e33 !default;
$secondary-40: #553346 !default; 
$secondary-50: #664558 !default;
$secondary-60: #8d7382 !default; 
$secondary-70: #ad99a4 !default;
$secondary-80: #c2b4bc !default;
$secondary-90: #dcd2d8 !default;
$secondary-95: #f6eef3 !default;
$secondary-99: #faf5f8 !default;

$tertiary-0: #ee7006 !default;
$tertiary-10: #eb7f09 !default;
$tertiary-20: #f18d06 !default;
$tertiary-30: #f59a1e !default;
$tertiary-40: #f6af20 !default;
$tertiary-50: #f7bb25 !default;
$tertiary-60: #f8c436 !default;
$tertiary-70: #f9d056 !default;
$tertiary-80: #fbdc85 !default;
$tertiary-90: #fdeab4 !default;
$tertiary-95: #fef7e2 !default;
$tertiary-99: #fefbf0 !default;

$error-0: #740f14 !default;
$error-10: #871b20 !default;
$error-20: #992228 !default;
$error-30: #b0333b !default;
$error-40: #b9434b !default;
$error-50: #c95d63 !default;
$error-60: #da8086 !default;
$error-70: #eaa4a8 !default;
$error-80: #f1c4c6 !default;
$error-90: #fcdddd !default;
$error-95: #faecec !default;
$error-99: #fcf3f3 !default;


$neutral-0: #000000 !default;
$neutral-10: #262626 !default;
$neutral-20: #434343 !default;
$neutral-30: #555555 !default;
$neutral-40: #7b7b7b !default;
$neutral-50: #9d9d9d !default;
$neutral-60: #c4c4c4 !default;
$neutral-70: #d9d9d9 !default;
$neutral-75: #dfdfdf !default;
$neutral-80: #e3e3e3 !default;
$neutral-90: #ededed !default;
$neutral-95: #f5f5f5 !default;
$neutral-100: #ffffff !default;

$neutral-variant-0: #16211e !default;
$neutral-variant-10: #26332f !default;
$neutral-variant-20: #364541 !default;
$neutral-variant-30: #495c57 !default;
$neutral-variant-40: #60716d !default;
$neutral-variant-50: #738783 !default;
$neutral-variant-60: #93a4a0 !default; 
$neutral-variant-70: #aebcb8 !default;
$neutral-variant-80: #c2d1cd !default;
$neutral-variant-90: #dbe8e5 !default;
$neutral-variant-95: #ebf5f3 !default;
$neutral-variant-99: #f5fefc !default;
// end company colors //


$primary: $primary-40;
$primary-38: rgba($primary, 0.38);
$primary-disabled: rgba($primary, 0.12);
$primary-pressed: $primary-30;
$primary-container: $primary-80;
$on-primary-container: $primary-10;
$on-primary: $neutral-100;
$on-primary-60: rgba($on-primary, 0.60);
$on-primary-12: rgba($on-primary, 0.12);
$on-primary-8: rgba($on-primary, 0.08);
$primary-hover: $primary-50;
$on-primary-alt: $secondary-0;
$inverse-primary: $primary-60;

$secondary: $secondary-20;
$secondary-disabled: rgba($secondary, 0.12);
$secondary-disabled: rgba($secondary, 0.38);
$on-secondary: $neutral-100; 
$secondary-container: $secondary-90;
$on-secondary-container: $secondary-10;
$on-secondary-alt: $primary-70;
$secondary-hover: $secondary-40;
$secondary-pressed: $secondary-30;

$tertiary: $tertiary-50;
$tertiary-container: $tertiary-90;
$on-tertiary: $neutral-10;
$on-tertiary-container: $tertiary-0;

$error: $error-30;
$on-error: $neutral-100;
$error-container: $error-80;
$on-error-container: $error-0;

$background: $neutral-100;
$on-background: $neutral-10;
$shadow: $neutral-10;

$surface: $neutral-95;
$surface-variant: $neutral-variant-95;
$on-surface: $neutral-10;
$on-surface-8: rgba($on-surface, 0.08);
$on-surface-12: rgba($on-surface, .12);
$on-surface-38: rgba($on-surface, .38);
$on-surface-variant: $neutral-variant-10;
$inverse-surface: $neutral-20;
$inverse-on-surface: $neutral-90; 

$outline: $neutral-variant-50;
$outline-38: rgba($outline, 0.38);