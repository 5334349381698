.ls-modal-container {
  display: flex;
  flex-direction: column;
  background-color: $surface;
  border-radius: 0.5rem;
  padding: 2.5rem;
  
  .modal-action-btns {
    display: flex;
    margin-top: 2rem;
    margin-bottom: 1.5rem;
    flex-direction: row;
    justify-content: space-between;
  }
}

@include media-breakpoint-down(sm) {
  .ls-modal-container {
    .modal-action-btns {
      flex-direction: column;
      button:first-child {
        margin-bottom: 1.5rem;
      }
    }
  }
}