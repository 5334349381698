.scroll {
    overflow-y: scroll;
    padding-left: 1px;
    height: 100%;

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-track {
        background: transparent !important;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background: $primary !important;
        border-radius: 10px;
        min-height: 9rem;
    }
}