﻿.title {
  &.l {
    @include text-size(1.375rem, 2rem, 600);
  }
  &.m {
    @include text-size(1.25rem, 1.4rem, 600);
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.0075em;
    
  }
  &.s {
    @include text-size(1.125rem, 1.33rem, 600);
  }
}
