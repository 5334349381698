ls-breadcrumbs {
    font-family: 'Montserrat', sans-serif;
    @include text-size(0.875rem, 1.25rem, 600);
    letter-spacing: 0.013em;
    text-transform: none;

    div {
        div {
            *:not(.material-icons) {
                padding-top: 0.125rem;
            }
        }
    }
}
