﻿@use "sass:meta";
@import '../../styles/theme-colors';

@mixin config-light-outline($name, $background1, $color1, $background2, $color2, $color3) {
  &.ls-badge-#{$name} {
    @include background-and-color($background1, $color1);
  }
  &.ls-badge-#{$name}-light {
    @include background-and-color($background2, $color2);
  }
  &.ls-badge-#{$name}-outline {
    @include background-and-color($background, $color3);
    border: 0.0625rem;
    border-style: solid;
    border-radius: 0.25rem;
    border-color: $color3;
  }
}

@mixin badge-configurations() {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 0.25rem;

  @include config-light-outline(primary, $primary, $on-primary, $primary-container, $on-primary-container, $primary);
  @include config-light-outline(secondary, $secondary, $on-secondary, $secondary-container, $on-secondary-container, $secondary);
  @include config-light-outline(tertiary, $tertiary, $on-tertiary, $tertiary-container, $on-tertiary, $primary-20);
  @include config-light-outline(warning, $on-tertiary-container, $background, $tertiary-container, $on-tertiary-container, $on-tertiary-container);
  @include config-light-outline(error, $error, $on-error, $error-container, $on-error-container, $error);
  @include config-light-outline(neutral, $inverse-surface, $inverse-on-surface, $inverse-on-surface, $inverse-surface, $inverse-surface);
  @include config-light-outline(neutral-variant, $outline, $surface-variant, $neutral-variant-90, $on-surface, $outline);
}

.ls-badge {
  height: 2.625rem;
  justify-content: center;
  padding: 0.75rem 1rem;

  @include badge-configurations();

  &.ls-badge-support {
    width: 7.5rem;
  }
}

.ls-icon-badge {
  justify-content: space-between;
  padding: 0.375rem;
  min-height: 1.75rem;
  gap: 0.25rem;
  width: fit-content;
  &.l {
    padding: 1.125rem;
  }

  & span.material-icons {
    font-size: 0.8125rem !important;
  }

  @include badge-configurations();
}

@mixin notification-color($color) {
  text-align: center;
  background: $color;
  font-size: 0.7rem;
  border-radius: 100%;

  &.s {
    width: 0.5rem;
    height: 0.5rem;
  }
  &.l {
    width: 1rem;
    height: 1rem;
  }
  &.xl {
    width: 1.5rem;
    height: 1rem;
  }
}

.ls-badge-notification-primary {
  @include notification-color($primary);
}

.ls-badge-notification-error {
  @include notification-color($error);
}

@mixin status-colors($args...) {
  @each $status, $color in meta.keywords($args) {
    .ls-badge-app-#{$status} {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 12.5rem;
      height: 2.625rem;
      border: 0.0625rem;
      border-style: solid;
      border-radius: 0.25rem;
      border-color: $color;
      color: $color;
    }
  }
}

@include status-colors(
  $inprogress: $on-tertiary-container,
  $submitted: $outline,
  $undreview: $secondary,
  $complete: $tertiary,
  $approved: $primary
);

@mixin role-colors($args...) {
  @each $role, $color in meta.keywords($args) {
    .ls-badge-user-role-#{$role} {
      width: fit-content;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 0.25rem;
      padding: 0.375rem;
      border-radius: 0.25rem;
      border: solid 0.063rem $color;
      background-color: $background;
      label {
        @include text-size(0.75rem, 1.17rem, 600);
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: $color;
        height: 0.875rem;
      }
      span {
        color: $color;
        font-size: 0.75rem;
      }
    }
  }
}

@include role-colors(
  $admin: $primary,
  $contributor: $inverse-surface,
);
