﻿a.ls-hyperlink {
  color: $primary;
  @include text-size(1rem, 1.875rem, 700);
  text-decoration: none;
  &:hover {
    text-decoration: 0.125rem solid underline;
    text-underline-offset: 0.5rem;
  }
  &:focus {
    outline: none;
    color: $primary-pressed;
    text-decoration: 0.125rem solid underline;
    text-underline-offset: 0.5rem;
  }
  &:active {
    color: $primary-pressed;
  }
}
